.select__control {
    border-top: none;
    border-left: none;
    border-right: none;
    background-color: transparent;
}

/* Datepicker */
.react-datepicker {
    box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
    -webkit-box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
    -moz-box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
    border: 2px solid #f2f4f6;
}

.react-datepicker__navigation {
    margin: 5px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    display: flex;
    flex-direction: column;
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    right: 50px;
}

.react-datepicker__month-read-view {
    margin: 10px 0;
}

.react-datepicker__header {
    background-color: transparent;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f2f4f6;
}

.react-datepicker__day,
.react-datepicker__time-name {
    border: 1px solid #008ffd;
    color: #008ffd;
    border-radius: 0.3rem;
}

.react-datepicker__day:hover {
    background-color: #d9efff;
    /* color: white; */
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    border: none;
    background-color: transparent;
    color: #ccc;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
    background-color: #008ffd;
    color: white;
    border: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background-color: #008ffd!important;
    border: none;
    color: white;
}

.react-datepicker__navigation {
    border: 1px solid #e5e5e7;
    border-radius: 0.5rem;
    height: 28px;
    width: 28px;
    box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
    -webkit-box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
    -moz-box-shadow: 1px 1px 4px -1px rgb(0 0 0 / 68%);
}
.react-datepicker__navigation-icon--previous::before {
    right: -5px;
}
.react-datepicker__navigation-icon::before  {
    top: 7px;
}
.react-datepicker__navigation-icon--next::before {
    left: -4px;
}
