.map-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    /* total - navbar - paddings */
    height: calc(100vh - 64px - 48px);
    max-height: calc(100vh - 64px - 48px);
    overflow-y: hidden;
    overflow-x: hidden;
}

.maps-area {
    height: 100%;
    max-height: 100%;
    position: relative;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    /* align-items: center; */
    border-right: 2px solid #f6f6f6 !important;
}

.maps-area-container {
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
}

.maps-toolbar {
    display: flex;
    height: 100%;
    overflow-y: auto;
    flex-direction: column;
    flex: 1;
    padding: 0rem 0.5rem;
}

.maps-filters {
    display: flex;
    height: 95%;
    max-height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 0.95;
}

.filtros-seccion {
    width: 100%;
}

.filtro {
    margin-top: 1rem;
}
.filtro:first-child {
    margin-top: 0.5rem !important;
}

.btn-flotante {
    position: absolute !important;
    box-shadow: none !important;
    background-color: #f6f6f6 !important;
    left: 1.5rem;
    top: calc(1.8rem - 10px);
    z-index: 900;
}

.maps-button {
    display: flex;
    padding: 1rem 3rem;
    flex: 0.05;
    border-top: 2px solid #f6f6f6 !important;
}

.tabla-mapa-container {
    padding: 2rem;
}

@media (max-width: 960px) {
    .map-container {
        flex-direction: column-reverse;
        height: auto;
        max-height: fit-content;
    }

    .maps-button {
        border-bottom: 2px solid #f6f6f6 !important;
    }

    .maps-area {
        border-right: none !important;
    }

    .tabla-mapa-container {
        max-width: 80vw;
        max-width: calc(100vw - 48px);
        overflow-x: auto;
        padding: 2rem 0rem;
    }
}

.map-label {
    color: #faa307;
}

.custom .leaflet-popup-tip,
.custom .leaflet-popup-content-wrapper {
    background: #f2f2f2e0;
    color: #3d3d3d;
}

.sendero-move {
    fill: #1fbb24;
}
