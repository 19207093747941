@import '../../node_modules/react-toastify/dist/ReactToastify.css';
@import './flex.css';
@import './forms.css';
@import './inputs.css';
@import './utils.css';
@import './maps.css';

/* body {
    background-color: aquamarine !important;
} */
.dx-theme-generic-typography .dx-font-xs, .dx-theme-generic-typography h6, .dx-theme-generic-typography small {
    font-weight: normal;
    font-size: 1rem;
}