.w-100 {
    width: 100%;
}

.separador {
    border-bottom: 1px solid gray;
    margin-bottom: 0.7rem;
}

.label-small {
    font-size: 10pt;
    color: rgba(0, 0, 0, 0.38);
}

.legend {
    width: 1.8rem;
    height: 0.5rem;
    border-radius: 0.2rem;
    margin-right: 1rem;
}

.bk-root .bk-tool-icon-help {
    display: none;
}

.bk-root .bk-toolbar .bk-logo {
    display: none;
}

body {
    background: #000;
}
h1 {
    color: #fff;
    text-align: center;
}
.circular-progress {
    position: relative;
    margin: auto;
}
.circular-progress, .circular-progress:before, .circular-progress:after {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
}
.circular-progress:before, .circular-progress:after {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}
.circular-progress:before {
    line-height: 8rem;
    padding: 0.5em;
    box-sizing: border-box;
    font-size: 1.8rem;
}
.circular-progress:after {
    line-height: 13rem;
}
.jscript {
    background: linear-gradient(0deg, orange 50%, rgba(255, 165, 0, .2) 50%);
    transform: rotate(18deg);
}
.jscript:before, .jscript:after {
    color: orange;
}
.jscript:before {
    content: "js";
    background: #FAFAFA content-box;
    transform: rotate(-18deg);
    text-transform: uppercase;
}
table.data-grid {
    width: 100%;
}
/* .jscript:after {
    content: "10%";
    background: linear-gradient(transparent 50%, #000 50%);
    transform: scale(1.1) rotate(-18deg);
} */
