.form-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    padding: 5% 10%;
}

.buttons-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 992px) {
    .form-container {
        padding: 5%;
    }

    .buttons-box {
        flex-direction: column;
        align-items: center;
    }

    .buttons-box button {
        margin: 0.5rem 0 !important;
        width: 100%;
    }
  }